import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { Title as DefaultTitle } from '@styledComponents/index';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: 1200px) {
    background: url(${require('@images/footer-bg@2x.jpg')}) no-repeat;
    background-size: 144rem 67.4rem;
    height: 67.4rem;
    justify-content: flex-end;
  }
`;

export const Title = styled(DefaultTitle)`
  font-family: 'Avenir';
  font-weight: 700;
  line-height: 3.4rem;
`;

export const FooterContainer = styled.div`
  background-color: ${props => props.theme.white};
  padding: 4.3rem 1.4rem 0;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: 1200px) {
    width: 90rem;
    margin: 4rem 0 0 0;
    padding: 5.9rem 15rem 1.4rem 8.9rem;
  }
`;

export const TopFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SocialLink = styled.a`
  display: inline-block;
  margin: 0 1rem;
`;

export const Facebook = styled.img.attrs({
  src: require('@images/facebook.svg'),
  alt: 'Our Facebook page'
})`
  width: 2rem;
  height: 2rem;
`;

export const LinkedIn = styled(Facebook).attrs({
  src: require('@images/linkedin.svg'),
  alt: 'Our Linkedin page'
})``;

export const Twitter = styled(Facebook).attrs({
  src: require('@images/twitter.svg'),
  alt: 'Our Twitter page'
})``;

export const Youtube = styled(Facebook).attrs({
  src: require('@images/youtube.svg'),
  alt: 'Our Youtube page'
})``;

export const ContactDetails = styled.div`
  font-family: 'Avenir';
  font-size: 1.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  line-height: 2.4rem;
  > div {
    > span {
      display: block;
    }
  }
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Map = styled.div`
  margin: 2.3rem 0;
  iframe {
    border: 0;
    height: 40rem;
    width: 100%;
    @media (min-width: 1200px) {
      height: 27.1rem;
    }
  }
`;

export const BottomFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Link = styled(GatsbyLink)`
  font-family: 'Avenir';
  font-size: 1.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  margin-right: 2rem;
  text-decoration: none;
`;

export const Copyright = styled.div`
  font-family: 'Avenir';
  font-size: 1.4rem;
  color: ${props => props.theme.txtColor};
  letter-spacing: 0;
  margin: 2rem 0 4rem;
  text-align: left;
  @media (min-width: 1200px) {
    margin: 0;
  }
`;
