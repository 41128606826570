import styled from 'styled-components';

export const ScrollButton = styled.div`
  bottom: 8rem;
  border-radius: 3rem;
  padding: 1rem;
  position: fixed;
  right: 1.6rem;
  z-index: 100;
  cursor: pointer;
  transition: visibility 0.6s ease, opacity 0.6s ease;
  visibility: ${(props) => props.active ? 'visible' : 'hidden'};
  opacity: ${(props) => props.active ? '1' : '0'};
  background-color: ${(props) => props.theme.primaryColor};
  width: 1.8rem;
  height: 1.8rem;
  border: 0.1rem solid ${props => props.theme.bgColor};
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
`;