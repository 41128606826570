import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, Main } from '@styledComponents/index';
import { defaultTheme, darkTheme } from '@styledComponents/theme';
import AppContext from '@utils/app-context';
import Header from './header/index';
import Footer from './footer/index';
// import Preloader from '@components/preloader';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { debounce, sendToTop } from '@utils/helpers';
import ScrollTopButton from '@components/scroll-top-button/index';
import MobileMenu from './header/mobile-menu/index';

const toggleTheme = setTheme => {
  return () => {
    setTheme(theme => (theme === defaultTheme ? darkTheme : defaultTheme));
  };
};

const showPreloader = setShow => {
  return status => {
    setShow(status);
  };
};

function onScroll($html, setShowScrollTopBtn, setHeaderFixed) {
  if ($html.scrollTop > 180) {
    setShowScrollTopBtn(true);
  } else {
    setShowScrollTopBtn(false);
  }
  if ($html.scrollTop > 10) {
    setHeaderFixed(true);
  } else {
    setHeaderFixed(false);
  }
}

const DefaultLayout = ({ children, location }) => {
  const [theme, setTheme] = useState(defaultTheme);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(false);
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);
  const [size, setSize] = useState(
    typeof window !== 'undefined'
      ? {
          width: window.innerWidth,
          height: window.innerHeight,
        }
      : {
          width: 0,
          height: 0,
        }
  );
  const [showMobMenu, setShowMobMenu] = useState(false);

  useEffect(() => {
    const $html = document.querySelector('html');
    function handleResize() {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    function handelScrolling() {
      onScroll($html, setShowScrollTopBtn, setHeaderFixed);
    }
    const debouncedHandleResize = debounce(handleResize, 1000);

    window.addEventListener('resize', debouncedHandleResize);
    window.document.addEventListener('scroll', handelScrolling);

    handleResize();
    handelScrolling();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      window.document.removeEventListener('scroll', handelScrolling);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        toggleTheme: toggleTheme(setTheme),
        showPreloader: showPreloader(setLoading),
        size,
        setShowMobMenu,
        showMobMenu,
      }}
    >
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle showMobMenu={showMobMenu} />
          <Header theme={theme} location={location} fixed={headerFixed} />
          <MobileMenu />
          <Main>{children}</Main>
          <ScrollTopButton
            active={showScrollTopBtn}
            onClick={() => sendToTop()}
          />
          <Footer />
          {/* <Preloader show={loading}/> */}
        </>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DefaultLayout;
