import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

export const Logo = styled(Link)`
  outline: none;
  padding: 1rem 0 0 1.4rem;
  @media (min-width: 1200px) {
    position: absolute;
    left: 3.6rem;
    padding-left: 0;
  }
`;

export const LogoImg = styled.img.attrs({
  src: require('@images/wareef_logo.svg'),
  alt: 'Logo image',
})`
  width: 9.2rem;
  height: 4.1rem;
  transition: all 0.4s ease;

  @media (min-width: 1200px) {
    width: 13rem;
    height: 5.7rem;
  }
`;

export const Container = styled.div`
  height: 6.4rem;
  background-color: ${props => props.theme.primaryColor};
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 0.3rem 0.5rem 0 rgba(62, 62, 62, 0.3);
  transition: all 0.4s ease;

  @media (min-width: 1200px) {
    height: ${props => (props.fixed ? '6.4rem' : '8.7rem')};
    justify-content: center;
    ${props =>
      props.fixed &&
      css`
        ${LogoImg} {
          width: 9.2rem;
          height: 4.1rem;
        }
      `};
  }
`;

export const BurgerMenu = styled.button`
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  width: 5.2rem;
  height: 6.4rem;
  background-color: ${props => props.theme.primaryColor};
  @media (min-width: 1200px) {
    display: none;
  }
`;

export const MobileMenuContainer = styled.div`
  svg {
    #menu {
      transition: fill 0.4s linear;
    }
  }
`;

export const MobileList = styled.div`
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  opacity: ${props => (props.active ? '1' : '0')};
  position: absolute;
  top: 6.4rem;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: opacity 0.4s linear;
  display: flex;
  flex-direction: column;
`;

export const MobileMenuItem = styled(Link)`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 2.2rem;
  background-color: ${props => props.theme.primaryColor};
  color: ${props =>
    props.selected ? props.theme.menuSelectedColor : props.theme.white};
  border-bottom: 0.1rem solid ${props => props.theme.menuBorderColor};
  padding: 1.7rem;
  letter-spacing: 0;
  text-decoration: none;
  transition: color 0.4s linear;
  &:first-child {
    border-top: 0.1rem solid ${props => props.theme.menuBorderColor};
  }
  &:last-child {
    border-bottom: 0;
  }
`;

export const MobileMenuItemGroup = styled.div`
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 2.2rem;
  background: ${props => props.theme.primaryColor};
  color: ${props =>
    props.selected ? props.theme.menuSelectedColor : props.theme.white};
  border-bottom: 0.1rem solid ${props => props.theme.menuBorderColor};
  padding: 1.7rem;
  letter-spacing: 0;
  transition: color 0.4s linear;
  position: relative;
  cursor: pointer;
  &::before {
    content: ' ';
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 1.1rem;
    top: 2.8rem;
    background: url(${require('@images/arrow-up.svg')}) no-repeat center center;
    transition: all 0.4s linear;
    transform: rotate(0deg);
    ${props =>
      !props.isOpen &&
      css`
        transform: rotate(180deg);
      `};
  }
  &::after {
    content: ' ';
    ${props =>
      !props.isOpen &&
      css`
        display: none;
      `};
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 1rem;
    border-color: transparent transparent ${props => props.theme.white}
      transparent;
    bottom: -0.1rem;
    right: 1rem;
  }
`;

export const MobileMenuItemGroupDetails = styled.div`
  display: ${props => (props.active ? 'flex' : 'none')};
  background: ${props => props.theme.bgColor};
  padding: 0 1.4rem;
  flex-direction: column;
  > a {
    text-decoration: none;
  }
`;

export const SubMenuItem = styled.div`
  margin: 2rem 0 2.6rem;
  @media (min-width: 1200px) {
    width: 33rem;
    margin: 0 0 0.6rem;
    padding: 2rem;
    border-left: 0.2rem solid transparent;
    &:hover {
      border-color: ${props => props.theme.menuSelectedColor};
      background-color: ${props => props.theme.menuHoverBg};
    }
  }
`;

export const SubMenuItemText = styled.h3`
  color: ${props => props.theme.txtColor};
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 4.2rem;
  margin: 0;
`;

export const SubMenuItemParagraph = styled.p`
  color: ${props => props.theme.paragraphColor};
  font-family: 'Avenir Book';
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 2rem;
  margin: 0;
`;
