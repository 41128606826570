import React, { useContext } from 'react';
import AppContext from '@utils/app-context';
import Menu from './menu/index';
import {
  BurgerMenu,
  Container,
  Logo,
  LogoImg,
  MobileMenuContainer,
} from './styles';
import Icon from './mobile-menu/icon';

const Header = ({ location, fixed, theme }) => {
  const { setShowMobMenu: setActive, showMobMenu: active } = useContext(
    AppContext
  );

  return (
    <Container fixed={fixed}>
      <Logo to="/">
        <LogoImg />
      </Logo>
      <MobileMenuContainer>
        <BurgerMenu
          active={active.toString()}
          onClick={() => setActive(state => !state)}
        >
          <Icon fill={active ? theme.menuSelectedColor : theme.white} />
        </BurgerMenu>
      </MobileMenuContainer>
      <Menu location={location} fixed={fixed} />
    </Container>
  );
};

export default Header;
