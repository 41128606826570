import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

export const MenuItem = styled.div`
  border-bottom: 0.4rem solid
    ${props =>
      props.active ? props.theme.menuSelectedColor : props.theme.primaryColor};
  height: 8.3rem;
  transition: all 0.4s ease;
`;

export const MenuItemGroup = styled.div`
  border-bottom: 0.4rem solid
    ${props =>
      props.active ? props.theme.menuSelectedColor : props.theme.primaryColor};
  height: 8.3rem;
  display: flex;
  align-items: center;
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.8rem;
  color: ${props =>
    props.active ? props.theme.menuSelectedColor : props.theme.white};
  cursor: pointer;
  padding: 0 2.4rem 0 1.7rem;
  transition: color 0.4s linear;
  position: relative;
  transition: all 0.4s ease;
  &:hover {
    color: ${props => props.theme.menuSelectedColor};
  }
  &::before {
    content: ' ';
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0rem;
    top: 3.4rem;
    background: url(${require('@images/arrow-up.svg')}) no-repeat center center;
    transition: all 0.4s ease;
    transform: rotate(0deg);
    ${props =>
      !props.isOpen &&
      css`
        transform: rotate(180deg);
      `};
  }
  &::after {
    content: ' ';
    ${props =>
      !props.isOpen &&
      css`
        display: none;
      `};
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 1rem;
    border-color: transparent transparent ${props => props.theme.white}
      transparent;
    bottom: -0.4rem;
    right: 0;
  }
`;

export const MenuItemGroupDetails = styled.div`
  position: absolute;
  z-index: 99;
  background: ${props => props.theme.bgColor};
  top: 8.65rem;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease;
  > div {
    display: ${props => (props.active ? 'flex' : 'none')};
    padding: 2.8rem 1.4rem 1.8rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 120rem;
    margin: 0 auto;
    > a {
      text-decoration: none;
    }
  }
`;

export const Link = styled(GatsbyLink)`
  display: inline-block;
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.8rem;
  color: ${props =>
    props.selected ? props.theme.menuSelectedColor : props.theme.white};
  padding: 0 1.7rem;
  height: 8.7rem;
  line-height: 8.7rem;
  text-decoration: none;
  transition: all 0.4s ease;

  &:hover {
    color: ${props => props.theme.menuSelectedColor};
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 1199px) {
    display: none;
  }
  ${props =>
    props.fixed &&
    css`
      ${MenuItem} {
        height: 6rem;
      }
      ${MenuItemGroup} {
        height: 6rem;
        &::before {
          top: 2.3rem;
        }
      }
      ${MenuItemGroupDetails} {
        top: 6.4rem;
      }
      ${Link} {
        height: 6rem;
        line-height: 6rem;
      }
    `};
`;
