export const defaultTheme = {
  primaryColor: '#14A562',
  secondaryColor: '#33AAC5',
  menuSelectedColor: '#FFD000',
  txtColor: '#053620',
  subtitleColor: '#73B815',
  paragraphColor: '#648476',
  bgColor: '#FFFFFF',
  secondaryBgColor: '#F6F8FA',
  quoteTxtColor: '#14A562',
  sliderTitle: '#F9C015',
  moreLink: '#F9C015',
  moreLink2: '#14A562',
  borderColor: '#C4D4DA',
  inputBorder: '#E1E8EE',
  menuBorderColor: 'rgba(255,255,255,0.2)',
  menuHoverBg: '#F5F9FC',
  tabBorder: '#D4D4D4',
  breadcrumbColor: '#053620',
  articleTxt: '#43484D',
  errorTxt: '#CA4A1F',
  white: '#FFFFFF',
  black: '#333333',
};

export const darkTheme = {
  ...defaultTheme,
  txtColor: '#FFFFFF',
  subtitleColor: '#F6F8FA',
  paragraphColor: '#73B815',
  bgColor: '#053620',
  secondaryBgColor: '#73B815',
};
