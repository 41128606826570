import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Avenir Book Oblique';
  src: url(${require('@assets/fonts/avenir-book-oblique.otf')}) format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Book';
  src: url(${require('@assets/fonts/avenir-book.ttf')}) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(${require('@assets/fonts/avenir-black.ttf')}) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(${require('@assets/fonts/avenir-heavy.ttf')}) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(${require('@assets/fonts/avenir-medium.ttf')}) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir';
  src: url(${require('@assets/fonts/avenir-light.ttf')}) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir Roman';
  src: url(${require('@assets/fonts/avenir-roman.ttf')}) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Heuristica';
  src: url(${require('@assets/fonts/heuristica-bold.ttf')}) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Heuristica';
  src: url(${require('@assets/fonts/heuristica-regular.ttf')}) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Karla';
  src: url(${require('@assets/fonts/karla-regular.ttf')}) format('truetype');
  font-weight: 400;
  font-style: normal;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font: 62.5% open-sans;
  box-sizing: border-box;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100%;
}
body {
  box-sizing: border-box;
  background-color: ${props => props.theme.bgColor};
  color: hsla(0, 0%, 0%, 0.8);
  color: ${props => props.theme.txtColor};
  font-family: 'Open sans', sans-serif;
  font-weight: normal;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin: 0;
  word-wrap: break-word;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: ${props => (props.showMobMenu ? 'hidden' : 'initial')};
}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.bgColor || props.theme.bgColor};
  position: relative;
  @media (min-width: 1200px) {
    flex-direction: ${props => props.deskDir || 'row'};
    margin: ${props => props.deskMargin || '0'};
  }
`;

export const Section = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || '2.7rem 1.4rem 3.5rem'};
  ${props =>
    props.flexAlign &&
    css`
      justify-content: ${props.flexAlign};
      align-items: center;
    `};
  @media (min-width: 1200px) {
    flex-direction: ${props => props.deskDirection || 'column'};
    margin: ${props => props.deskMargin || '0'};
    padding: ${props => props.deskPadding || '2.7rem 1.4rem 3.5rem'};
    width: ${props => props.deskWidth || '100%'};
  }
`;

export const Subtitle = styled.h3`
  color: ${props => props.theme.subtitleColor};
  font-family: 'Avenir';
  font-weight: 700;
  font-size: ${props => props.fontSize || '1.5rem'};
  letter-spacing: 0.16rem;
  text-transform: uppercase;
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  text-align: ${props => props.align || 'left'};
  @media (min-width: 1200px) {
    text-align: ${props => props.deskAlign || 'left'};
  }
`;

export const Title = styled.h1`
  color: ${props => props.theme.txtColor};
  font-family: 'Heuristica';
  font-weight: 700;
  font-size: 2.4rem;
  letter-spacing: 0;
  line-height: 2.8rem;
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.paragraphColor};
  font-family: 'Avenir';
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  @media (min-width: 1200px) {
    ${props =>
      props.deskMaxWidth &&
      css`
        max-width: ${props.deskMaxWidth};
      `};
  }
`;

export const Button = styled.button`
  background: ${props => props.theme.moreLink2};
  color: ${props => props.theme.white};
  cursor: pointer;
  font-family: 'Avenir';
  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;
  border: 0;
  padding: 1rem 2rem;
  width: ${props => props.width || '100%'};
  outline: none;
  @media (min-width: 600px) {
    ${props =>
      props.tabletWidth &&
      css`
        width: ${props.tabletWidth};
      `};
  }
  @media (min-width: 1200px) {
    ${props =>
      props.deskWidth &&
      css`
        width: ${props.deskWidth};
      `};
  }
`;

export const Main = styled.main`
  margin-top: 6.4rem;
  @media (min-width: 1200px) {
    margin-top: 8.7rem;
  }
`;
