import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { Link } from 'gatsby';
import AppContext from '@utils/app-context';
import {
  MobileList,
  MobileMenuItem,
  MobileMenuItemGroup,
  MobileMenuItemGroupDetails,
  SubMenuItem,
  SubMenuItemText,
  SubMenuItemParagraph,
} from '../styles';

const MobileMenu = () => {
  const { setShowMobMenu: setActive, showMobMenu: active } = useContext(
    AppContext
  );
  const [showServices, setShowServices] = useState(false);
  const [pathname, setPathname] = useState('/');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPathname(window.location.pathname);
    }
  }, []);

  return (
    <div>
      <MobileList active={active}>
        <MobileMenuItem
          to={'/'}
          selected={pathname === '/' || pathname === '/demo'}
        >
          Home
        </MobileMenuItem>
        <MobileMenuItem
          to={'/about-us'}
          selected={pathname.includes('/about-us')}
        >
          About
        </MobileMenuItem>
        <MobileMenuItemGroup
          selected={pathname.includes('/services/')}
          onClick={() => setShowServices(state => !state)}
          isOpen={showServices}
        >
          <span>Services</span>
        </MobileMenuItemGroup>
        <MobileMenuItemGroupDetails active={showServices}>
          <Link
            to={'/services/vendor-management'}
            onClick={() => {
              setShowServices(state => !state);
              setActive(state => !state);
            }}
          >
            <SubMenuItem>
              <SubMenuItemText>Vendor Management</SubMenuItemText>
              <SubMenuItemParagraph>
                We provide vendor management services that help clients find the
                right mix of vendors for their IT projects.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </Link>
          <Link
            to={'/services/quality-assurance'}
            onClick={() => {
              setShowServices(state => !state);
              setActive(state => !state);
            }}
          >
            <SubMenuItem>
              <SubMenuItemText>Quality Assurance</SubMenuItemText>
              <SubMenuItemParagraph>
                Our quality assurance and testing services ensure your IT
                solution matches the requirements of the business.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </Link>
          <Link
            to={'/services/project-management'}
            onClick={() => {
              setShowServices(state => !state);
              setActive(state => !state);
            }}
          >
            <SubMenuItem>
              <SubMenuItemText>Project Management</SubMenuItemText>
              <SubMenuItemParagraph>
                We offer full project management services with the ultimate goal
                of delivering a project’s scope requirements.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </Link>
          <Link
            to={'/services/logistics'}
            onClick={() => {
              setShowServices(state => !state);
              setActive(state => !state);
            }}
          >
            <SubMenuItem>
              <SubMenuItemText>Logistics</SubMenuItemText>
              <SubMenuItemParagraph>
                Wareef offers logistics support services by arranging for visa
                processing, as well as room, board and transportation for any
                skilled resources that need to be outsourced from abroad for an
                IT project.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </Link>
          <Link
            to={'/services/local-arabic-content'}
            onClick={() => {
              setShowServices(state => !state);
              setActive(state => !state);
            }}
          >
            <SubMenuItem>
              <SubMenuItemText>Local Arabic Content</SubMenuItemText>
              <SubMenuItemParagraph>
                With a team of native Arabic speakers, Wareef ensures the
                bilingual nature of its IT projects, as well as the management
                of those projects.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </Link>
        </MobileMenuItemGroupDetails>
        <MobileMenuItem
          to={'/resources'}
          selected={
            pathname.includes('/resources') ||
            pathname.includes('/blogs') ||
            pathname.includes('/articles')
          }
        >
          Resources
        </MobileMenuItem>
        <MobileMenuItem
          to={'/clients'}
          selected={pathname.includes('/clients')}
        >
          Clients
        </MobileMenuItem>
        <MobileMenuItem
          to={'/contact-us'}
          selected={pathname.includes('/contact-us')}
        >
          Contact us
        </MobileMenuItem>
      </MobileList>
    </div>
  );
};

export default MobileMenu;
