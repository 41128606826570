import React from 'react';
import {
  Paragraph,
} from '@components/styledComponents/index';
import {
  Title,
  Container,
  BottomFooter,
  ContactDetails,
  Copyright,
  Facebook,
  FooterContainer,
  FooterLinks,
  Link,
  LinkedIn,
  Map,
  SocialLink,
  SocialMedia,
  TopFooter,
  Twitter,
  Youtube,
} from './styles';

const Footer = () => {
  return (
    <Container>
      <FooterContainer>
        <TopFooter>
          <Title>Contact us</Title>
          <SocialMedia>
            <SocialLink href="https://www.facebook.com/pages/Wareef-United/136032093209181" target="_blank">
              <Facebook />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/wareef-united?trk=company_logo" target="_blank">
              <LinkedIn />
            </SocialLink>
            <SocialLink href="https://twitter.com/Wareef_United?lang=en" target="_blank">
              <Twitter />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCUvaN8F_qv0sD6_2wM0zOTQ" target="_blank">
              <Youtube />
            </SocialLink>
          </SocialMedia>
        </TopFooter>
        <Paragraph>
          If you have any questions don&apos;t hesitate to contact us
        </Paragraph>
        <ContactDetails>
          <div>
            <span>(+966) 11 478 6969</span>
            <span>info@wareefunited.com</span>
            <span>support@wareefunited.com</span>
            <span>Wareef.com.sa</span>
          </div>
          <br />
          <div>
            <span>Maceen Office Complex</span>
            <span>Building 5, Office 26</span>
            <span>Salahaldeen St., King Abdulaziz District</span>
            <span>PO Box 89436, Riyadh 11682</span>
          </div>
        </ContactDetails>
        <Map>
          <iframe src="//maps.google.com/maps?q=24.700453%2C46.722117&amp;output=embed"></iframe>
        </Map>
        <BottomFooter>
          <FooterLinks>
            {/* <Link to={'/'}>Terms &#38; Conditions</Link>
            <Link to={'/'}>Privacy Policy</Link> */}
          </FooterLinks>
          <Copyright>
            © {new Date().getFullYear()} Wareef United. All rights reserved
          </Copyright>
        </BottomFooter>
      </FooterContainer>
    </Container>
  );
};

export default Footer;
