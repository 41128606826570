export function getPath(location) {
  return location ? location.pathname : '';
}

import { useState, useEffect } from 'react';

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function sendToTop() {
  const scrollerComponent = document.querySelector('html');
  if (!scrollerComponent) {
    return;
  }
  const duration = 500;
  if (scrollerComponent.scrollTop === 0) return;

  const totalScrollDistance = scrollerComponent.scrollTop;
  let scrollY = totalScrollDistance;
  let oldTimestamp = null;

  function step(newTimestamp) {
    if (oldTimestamp !== null) {
      scrollY -=
        (totalScrollDistance * (newTimestamp - oldTimestamp)) / duration;
      if (scrollY <= 0) return (scrollerComponent.scrollTop = 0);
      scrollerComponent.scrollTop = scrollY;
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

export function onlyNumberKey(event) {
  const theEvent = event || window.event;
  let key;
  // Handle paste
  if (theEvent.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
  } else {
  // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
  }
  var regex = /[0-9]|\.|-|\+|\(|\)/;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}
