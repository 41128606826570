import React from 'react';

const Icon = props => (
  <svg width="20px" height="14px" viewBox="0 0 20 14">
    <g
      id="Mobile"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="XS-375px_wareef_home_page"
        transform="translate(-339.000000, -87.000000)"
      >
        <g id="Nav-Bar" transform="translate(0.000000, 64.000000)">
          <g
            id="menu"
            transform="translate(339.000000, 23.000000)"
            fill={props.fill || '#FFFFFF'}
          >
            <path
              d="M0,13.3333333 L20,13.3333333 L20,11.1111111 L0,11.1111111 L0,13.3333333 L0,13.3333333 Z M0,7.77777778 L20,7.77777778 L20,5.55555556 L0,5.55555556 L0,7.77777778 L0,7.77777778 Z M0,0 L0,2.22222222 L20,2.22222222 L20,0 L0,0 L0,0 Z"
              id="Shape-Copy"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
