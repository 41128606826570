import React, { useEffect, useRef, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { SubMenuItem, SubMenuItemText, SubMenuItemParagraph } from '../styles';
import {
  Link,
  MenuContainer,
  MenuItem,
  MenuItemGroup,
  MenuItemGroupDetails,
} from './styles';

const Menu = ({ fixed }) => {
  const [showServices, setShowServices] = useState(false);
  const menuDetailsRef = useRef(null);
  const servicesMenuRef = useRef(null);
  const [pathname, setPathname] = useState('/');

  useEffect(() => {
    function checkClick(e) {
      if (
        !servicesMenuRef.current.contains(e.target) &&
        !menuDetailsRef.current.contains(e.target)
      ) {
        setShowServices(false);
      }
    }
    document.addEventListener('click', checkClick);

    if (typeof window !== 'undefined') {
      setPathname(window.location.pathname);
    }

    return () => {
      document.removeEventListener('click', checkClick);
    };
  }, []);

  return (
    <MenuContainer fixed={fixed}>
      <MenuItem active={pathname === '/demo/' || pathname === '/'}>
        <Link to={'/'} selected={pathname === '/demo/' || pathname === '/'}>
          Home
        </Link>
      </MenuItem>
      <MenuItem active={pathname.includes('/about-us')}>
        <Link to={'/about-us'} selected={pathname.includes('/about-us')}>
          About
        </Link>
      </MenuItem>
      <MenuItemGroup
        ref={servicesMenuRef}
        active={pathname.includes('/services')}
        onClick={() => setShowServices(state => !state)}
        isOpen={showServices}
      >
        <span>Services</span>
      </MenuItemGroup>
      <MenuItemGroupDetails ref={menuDetailsRef} active={showServices}>
        <div>
          <GatsbyLink
            to={'/services/vendor-management'}
            onClick={() => setShowServices(state => !state)}
          >
            <SubMenuItem>
              <SubMenuItemText>Vendor Management</SubMenuItemText>
              <SubMenuItemParagraph>
                We provide vendor management services that help clients find the
                right mix of vendors for their IT projects.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </GatsbyLink>
          <GatsbyLink
            to={'/services/quality-assurance'}
            onClick={() => setShowServices(state => !state)}
          >
            <SubMenuItem>
              <SubMenuItemText>Quality Assurance</SubMenuItemText>
              <SubMenuItemParagraph>
                Our quality assurance and testing services ensure your IT
                solution matches the requirements of the business.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </GatsbyLink>
          <GatsbyLink
            to={'/services/project-management'}
            onClick={() => setShowServices(state => !state)}
          >
            <SubMenuItem>
              <SubMenuItemText>Project Management</SubMenuItemText>
              <SubMenuItemParagraph>
                We offer full project management services with the ultimate goal
                of delivering a project’s scope requirements.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </GatsbyLink>
          <GatsbyLink
            to={'/services/logistics'}
            onClick={() => setShowServices(state => !state)}
          >
            <SubMenuItem>
              <SubMenuItemText>Logistics</SubMenuItemText>
              <SubMenuItemParagraph>
                Wareef offers logistics support services by arranging for visa
                processing, as well as room, board and transportation for any
                skilled resources that need to be outsourced from abroad for an
                IT project.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </GatsbyLink>
          <GatsbyLink
            to={'/services/local-arabic-content'}
            onClick={() => setShowServices(state => !state)}
          >
            <SubMenuItem>
              <SubMenuItemText>Local Arabic Content</SubMenuItemText>
              <SubMenuItemParagraph>
                With a team of native Arabic speakers, Wareef ensures the
                bilingual nature of its IT projects, as well as the management
                of those projects.
              </SubMenuItemParagraph>
            </SubMenuItem>
          </GatsbyLink>
        </div>
      </MenuItemGroupDetails>
      <MenuItem
        active={
          pathname.includes('/resources') ||
          pathname.includes('/blogs') ||
          pathname.includes('/articles')
        }
      >
        <Link
          to={'/resources'}
          selected={
            pathname.includes('/resources') ||
            pathname.includes('/blogs') ||
            pathname.includes('/articles')
          }
        >
          Resources
        </Link>
      </MenuItem>
      <MenuItem active={pathname.includes('/clients')}>
        <Link to={'/clients'} selected={pathname.includes('/clients')}>
          Clients
        </Link>
      </MenuItem>
      <MenuItem active={pathname.includes('/contact-us')}>
        <Link to={'/contact-us'} selected={pathname.includes('/contact-us')}>
          Contact us
        </Link>
      </MenuItem>
    </MenuContainer>
  );
};

export default Menu;
